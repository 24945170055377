import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

import { Config } from '../config';
const baseURL = new Config().getBaseURL();
@Injectable({
  providedIn: 'root',
})
export class CreditAnalystService {
  openUrlUsers: string = `${baseURL}/users/openAPI`;
  constructor(private http: HttpClient) {}

  /* ===============================Analysis APIs=============================== */
  /* ====GET APIS==== */
  fetchStoredAnalysis(applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/company/creditUser/${applicationId}/creditAnalysis`
    );
  }

  fetchAnalysisData(companyId): Observable<any> {
    return this.http.get(
      `${baseURL}/company/creditUser/${companyId}/analysisData`
    );
  }

  sendICForApproval({companyId, applicationId}, data){
    return this.http.post(`${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/sentToICApproval`, data);
  }

  /* credit analyst send approval and credit-manager can approve or reject */
  dealTermApprove({companyId, applicationId}, data){
    return this.http.post(`${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/termsApproveNReject`, data);
  }


  syncCoApplicant({companyId, applicationId}){
    return this.http.get(`${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/syncCoApplicant`);
  }

  sendAnalysisToIC({ analysisId, analystComment }): Observable<any> {
    return this.http.post(
      `${baseURL}/company/creditAnalyst/${analysisId}/sendToIC`,
      { comment: analystComment }
    );
  }

  /* ====POST APIS==== */
  addAnalysis({ companyId, generatedAnalysis }): Observable<any> {
    console.log('received analysis terms==>', generatedAnalysis);
    return this.http.post(
      `${baseURL}/company/creditAnalyst/${companyId}/addCreditAnalysis`,
      generatedAnalysis
    );
  }

  generateAnalysis({ companyId, analysisInputs }): Observable<any> {
    return this.http.post(
      `${baseURL}/company/creditAnalyst/${companyId}/creditAnalysis`,
      analysisInputs
    );
  }
  /* ===============================Analysis APIs end=============================== */

  /* ===============================Termsheet APis================================== */
  /* ====GET APIs==== */
  fetchSavedTermsheets(applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/termsheet/analyst/application/${applicationId}/list`
    );
  }

  downloadTSFromDigio(digioSignInId) {
    let access = localStorage.getItem('token');
    let url = `${baseURL}/termsheet/${digioSignInId}/download?accessToken=${access}`;
    window.open(url, '_blank');
  }

  cancelTermsheet(digioSignInId): Observable<any> {
    return this.http.get(
      `${baseURL}/termsheet/analyst/${digioSignInId}/cancelTermsheet`
    );
  }

  /* ====POST APIs==== */
  saveTermsheet({
    termsheetData,
    analysisId: creditAnalysisId,
    companyId,
    applicationId,
  }): Observable<any> {
    return this.http.post(`${baseURL}/termsheet/analyst/addTermsheet`, {
      termsheetData,
      creditAnalysisId,
      companyId,
      applicationId,
    });
  }

  sendForSigning({ termsheetId, signers }): Observable<any> {
    return this.http.post(
      `${baseURL}/termsheet/analyst/${termsheetId}/sendForSignin`,
      signers
    );
  }
  /* ===============================Termsheet APis end================================== */

  /* ===============================Proposal Document APis================================== */

  /* ====GET APIs==== */
  fetchProposalDocList(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/list`
    );
  }

  fetchInvestingPartnersList(): Observable<any> {
    return this.http.get(
      `${baseURL}/proposalDocument/creditAnalyst/investingPartners`
    );
  }

  getInvestorName(
    companyId: string,
    applicationId: string,
    proposalDocId: string
  ): Observable<any> {
    return this.http.get(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/investorName/${proposalDocId}`
    );
  }

  fetchProposalDocStatus(companyId, applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocStatus`
    );
  }

  fetchBankAccounts(companyId: string) {
    return this.http.get(`${baseURL}/companies/${companyId}/bankaccounts`);
  }

  fetchCreditStepsStatus(applicationId: string) {
    return this.http.get(
      `${baseURL}/company/all/${applicationId}/analysisStatus`
    );
  }

  fetchValidateProposalDocument(
    companyId: string,
    applicationId: string
  ): Observable<any> {
    return this.http.get(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/validateProposalDocument`
    );
  }

  syncAnalysisDataForProposalDocument(
    companyId: string,
    applicationId: string
  ): Observable<any> {
    return this.http.get(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/syncAnalysis`
    );
  }

  fetchBounceAndBalance(
    companyId: string,
    applicationId: string
  ): Observable<any> {
    return this.http.get(
      `${baseURL}/proposalDocument/all/${companyId}/${applicationId}/bounceAndBalanceDetails`
    );
  }

  fetchMonthlyAverageBalance(companyId: string): Observable<any> {
    return this.http.get(
      `${baseURL}/companies/${companyId}/finbox-average-balance`
    );
  }

  /* ====POST APIs==== */
  addProposalDocument(companyId, applicationId): Observable<any> {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/addProposalDocument`,
      {}
    );
  }

  saveDirectorImage(
    companyId,
    applicationId,
    data?: FormData
  ): Observable<any> {
    let type;
    if (data.get('id')) type = 'directorImg';
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/uploads?type=${type}&id=${data.get(
        'id'
      )}`,
      data
    );
  }

  /* ===============================Agreement APis================================== */

  getUserByRoleIC(): Observable<any> {
    return this.http.get(`${this.openUrlUsers}/list_by_role?role=IC`);
  }

  /* ===============================Agreement APis end============================== */

  saveFinancialImages(
    companyId,
    applicationId,
    data?: FormData
  ): Observable<any> {
    let type = 'financialAnalysis';
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/uploads?type=${type}`,
      data
    );
  }

  uploadAdditionalFiles(companyId, applicationId, data: FormData) {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/additionalUploads`,
      data
    );
  }

  uploadFinancialPDFFiles(companyId, applicationId, data) {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/financialAnalysis`,
      data
    );
  }

  saveCompanyLogo(companyId, applicationId, data?: FormData): Observable<any> {
    let type = 'companyLogo';
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/uploads?type=${type}`,
      data
    );
  }

  sendToIC(proposalDocumentId: string, extraParams?: any): Observable<any> {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${proposalDocumentId}/sendToIC`,
      { ...extraParams }
    );
  }

  approveDoc(proposalDocumentId: string, comment: string): Observable<any> {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${proposalDocumentId}/approveProposalDocument`,
      {
        comment,
      }
    );
  }

  sendToInvestingPartners(
    proposalStatusId: string,
    investingPartner: string,
    fileUrls: any[]
  ): Observable<any> {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${proposalStatusId}/sendToInvestingPartners`,
      {
        investingPartner,
        fileUrls,
      }
    );
  }

  rejectProposalDoc(
    proposalStatusId: string,
    payload:object
  ): Observable<any> {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${proposalStatusId}/rejectProposalDocByIc`,payload);
  }

  /* ====PUT APIs==== */

  saveProposalDetails(companyId, applicationId, data, parmas?): Observable<any> {
    return this.http.put(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/proposalDetails`,
      data, 
      { params: { ...parmas } }
    );
  }

  /* ====PUT APIs==== */

  uploadComments({ applicationId, companyId, file }): Observable<any> {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/commentsupload?type=SupportingDocuments`,
      file
    );
  }

  uploadAdditionalAttachments({
    applicationId,
    companyId,
    file,
    fileLabel,
  }): Observable<any> {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/additionalAttachmentUpload?fileLabel=${fileLabel}`,
      file
    );
  }

  uploadOtherProposalReports({
    applicationId,
    companyId,
    file,
    fileType,
  }): Observable<any> {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/otherReportsUpload?type=${fileType}`,
      file
    );
  }

  /* ===============================Proposal Document APis end================================== */

  sendCommentOnEmail(companyId, applicationId, comment): Observable<any> {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/sendCommentOnEmail`,
      {
        comment,
      }
    );
  }

  changeAnalysisForProposal(companyId, applicationId, data): Observable<any> {
    return this.http.put(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/changeAnalysis`,
      data
    );
  }

  downloadAdditionalDocument(companyId, applicationId, url) {
    let header = { 'Content-Type': 'application/pdf' };
    return this.http.get(
      `${baseURL}/proposalDocument/all/${companyId}/${applicationId}/proposalDownloadAdditionalFiles`,
      {
        headers: header,
        params: {
          url,
        },
        responseType: 'blob',
      }
    );
  }

  /* ===============================Proposal Document APis end================================== */

  uploadCreditAnalyisSheet({
    applicationId,
    companyId,
    file,
    documentType,
  }): Observable<any> {
    return this.http.post(
      `${baseURL}/company/creditAnalyst/${companyId}/${applicationId}/uploadAnalysis?documentType=${documentType}`,
      file
    );
  }

  getDigioTermsheetStatus(digioId): Observable<any> {
    let documentType = 'termsheet';
    return this.http.get(
      `${baseURL}/termsheet/analyst/digio/${digioId}/getDocumentDetails`,
      { params: { documentType } }
    );
  }

  fetchInCredTermsheet({
    termsheetID,
    signer1,
    signer2,
    coApplicantDetails,
    dateOfIssuance,
  }): Observable<any> {
    return this.http.post(
      `${baseURL}/termsheet/analyst/${termsheetID}/downloadIncredTermsheet?`,
      { coApplicantDetails },
      { params: { signer1, signer2, dateOfIssuance } }
    );
  }

  deleteTermsheet({ termsheetID }): Observable<any> {
    return this.http.post(
      `${baseURL}/termsheet/analyst/${termsheetID}/deleteTermsheet?`,
      {}
    );
  }

  updateTermsheet({
    termsheetId,
    termsheetData,
    companyId,
    applicationId,
  }): Observable<any> {
    return this.http.post(
      `${baseURL}/termsheet/analyst/${termsheetId}/editTermsheet?`,
      { termsheetData, companyId, applicationId }
    );
  }

  sendSplitTermsRequest({ creditAnalysisID, splits }): Observable<any> {
    return this.http.post(
      `${baseURL}/company/creditAnalyst/${creditAnalysisID}/splitCreditAnalysis`,
      splits
    );
  }

  sendAnalysisToClient(analysisId): Observable<any> {
    return this.http.get(
      `${baseURL}/company/creditAnalyst/${analysisId}/sendUrlToUser`
    );
  }

  getAppVendors({ companyId, applicationId}): Observable<any> {
    return this.http.get(`${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/getVendorDetails`);
  }


  UpdateAppVendorsLimit({ companyId ,payload}): Observable<any> {
    return this.http.post(`${baseURL}/proposalDocument/creditAnalyst/${companyId}/setApplicationVendor`, payload);
  }

  getCollectionHistory({companyId}){
    return this.http.get(`${baseURL}/proposalDocument/creditAnalyst/${companyId}/CollectionHistory`);
  }

  uploadProposalTermsheetForClient({
    applicationId,
    companyId,
    file,
  }): Observable<any> {
    return this.http.post(
      `${baseURL}/proposalDocument/creditAnalyst/${companyId}/${applicationId}/proposalDocument/termsheetUpload`,
      file
    );
  }

}

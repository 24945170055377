import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpBackend } from '@angular/common/http';
// import { Http, ResponseContentType, RequestOptions } from '@angular/http';
import { Observable, of } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

import { Config } from '../config';
import { StringIterator } from 'lodash';
import { environment } from 'src/environments/environment';
const baseURL = new Config().getBaseURL();
const yblBalanceCheckUrl = new Config().yblBalanceCheckUrl
@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  [x: string]: any;
  openUrl: string = `${baseURL}/applications/openAPI`;
  openUrlUsers: string = `${baseURL}/users/openAPI`;
  roleWiseUrl: string = `${baseURL}/applications`;
  constructor(private http: HttpClient,private handler: HttpBackend) {
    this.httpClientBackend = new HttpClient(handler)
  }

  getAppId(applicationId): Observable<any> {
    return this.http.get(`${this.openUrl}/${applicationId}/appDetails`);
  }
  uploadAnalysisHandover(payload) {
    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(
      `${this.roleWiseUrl}/CreditToOps/${payload.bucketName}/uploadHandoverFiles`,
      payload.file
      // { headers }
    );
  }
  updateDealPriority(dealId, level) {
    return this.http.get(
      `${baseURL}/recovery/OpsRecovery/${dealId}/updatePriority?priorityLevel=${level}`
    );
  }
  getBankBalanceYbl() {
    return this.http.get(`${baseURL}/recovery/repayments/getBankBalance`)
  }
  getApplicationCount(): Observable<any> {
    var financeType = 'RBF';
    return this.http.get(
      `${this.openUrl}/count_by_status?financeType=${financeType}`
    );
  }
  getAllApplications(
    page: number,
    status: any,
    companyName: string = ''
  ): Observable<any> {
    var financeType = 'RBF';
    return this.http.get(
      `${this.openUrl}/list?status=${status}&company=${companyName}&page=${page}&financeType=${financeType}`
    );
  }

  getAllApplicationbyAnalystId(
    page: number,
    status: any,
    companyName: string = '',
    analystId: any
  ): Observable<any> {
    var params = {};
    var financeType = 'RBF';
    return this.http.post(
      `${this.roleWiseUrl}/Manager/${analystId}/analystApplications?status=${status}&company=${companyName}&page=${page}&financeType=${financeType}`,
      params
    );
  }

  getApplicationsForAction(page: number, status: any): Observable<any> {
    var params = {
      status: status,
    };
    return this.http.post(
      `${this.openUrl}/list_by_status?page=${page}`,
      params
    );
  }

  getRoleWiseApplications(
    page,
    status: any,
    role: any,
    companyName: string = '',
    count,
    addedThrough,
    financeType = 'RBF',
    startDate,
    endDate,
    dateType,
    referredBy
  ) {
    count = +count;
    var body = {};
    (status &&
      (body = {
        role,
        company: companyName,
        status,
        addedThrough,
        financeType,
        startDate,
        endDate,
        dateType,
        referredBy,
      })) ||
      (body = {
        role,
        company: companyName,
        addedThrough,
        financeType,
        startDate,
        endDate,
        dateType,
        referredBy,
      });
    return this.http.post(`${this.roleWiseUrl}/details`, body, {
      params: { page, count },
    });
  }
  getApplicationComments(applicationId: string): Observable<any> {
    return this.http.get(`${baseURL}/comments/${applicationId}/list`);
  }

  submitApplicationComment(applicationId: string, params: any) {
    return this.http.post(`${baseURL}/comments/${applicationId}/add`, params);
  }
  getUsers(rolename: string) {
    return this.http.get(`${this.openUrlUsers}/list_by_role?role=${rolename}`);
  }
  getAllDealAnalyst(rolename: string, companyId: string): Observable<any> {
    return this.http.get(
      `${baseURL}/companies/${companyId}/application/list_by_role?role=DEALS_A`
    );
  }

  getAnalysts() {
    return this.http.get(`${this.roleWiseUrl}/list_by_currentUser`);
  }

  applicationtransfer(applicationId: string, params) {
    return this.http.post(
      `${this.roleWiseUrl}/${applicationId}/assign`,
      params
    );
  }

  getApplicationStatusLogs(applicationId: string) {
    return this.http.get(`${this.openUrl}/${applicationId}/applicationStatus`);
  }
  downloadS3FileFromUrl(url: any) {
    return this.http.post(
      `${baseURL}/openApis/downloadFileFromS3`,
      { url },
      { responseType: 'blob' }
    );
  }

  sendApplicationToCm(applicationId: string) {
    return this.http.get(
      `${this.roleWiseUrl}/dm/${applicationId}/assign/creditManager`
    );
  }

  applicationtransferToCMANDCA(applicationId: string, body) {
    return this.http.post(
      `${this.roleWiseUrl}/${applicationId}/assign/creditManagerAndCreditAnalyst`,
      body
    );
  }
  /* ===============mca apis============= */
  fetchMcaStatus(applicationId): Observable<any> {
    return this.http.get(
      `${this.roleWiseUrl}/dm-da/${applicationId}/mca-status`
    );
  }

  sendMcaRequest(applicationId, type, retrying): Observable<any> {
    return this.http.get(
      `${this.roleWiseUrl}/dm-da/${applicationId}/${type}?retry=${retrying}`
    );
  }
  /* ===============mca apis end============= */

  /* ===============forward application to ops from credit manager============= */

  forwardApplicationToOps({ applicationId, dealSize }): Observable<any> {
    return this.http.post(
      `${this.roleWiseUrl}/CreditManager/${applicationId}/assign`,
      { dealSize }
    );
  }

  forwardApplicationToOps02(applicationId, payload: any): Observable<any> {
    console.log(payload);
    return this.http.post(
      `${this.roleWiseUrl}/CreditToOps/${applicationId}/sendHandOverEmail`,
      payload
    );
  }

  generateHandover(applicationId:string,payload:object): Observable<any> {
    return this.http.post(
      `${this.roleWiseUrl}/CreditToOps/${applicationId}/generateHandover`,payload
    );
  }

  sendHandoverToOps(handoverId:string,payload:object): Observable<any> {
    return this.http.post(
      `${this.roleWiseUrl}/CreditToOps/${handoverId}/sendHandoverToOps`,payload
    );
  }

  getGstAddress(Id: any) {
    return this.http.get(`${this.roleWiseUrl}/CreditToOps/${Id}/gstData`);
  }

  /* ===============forward application to ops from credit manager end============= */

  /* ===============update application status============= */

  updateApplicationStatus({ applicationId, status, comment }): Observable<any> {
    return this.http.post(
      `${this.roleWiseUrl}/dm-da/${applicationId}/updateStatus`,
      { status, comment }
    );
  }

  /* ===============update application status end============= */

  sendCredtoUsers(companyId: string): Observable<any> {
    return this.http.get(
      `${baseURL}/users/dealAnalyst/${companyId}/sendUserCredentails`
    );
  }

  sendCredtoUsersViaActivationLink(companyId: string): Observable<any> {
    return this.http.get(
      `${baseURL}/users/dealAnalyst/${companyId}/resendActivationLink`
    );
  }

  getUserListbyCompany(companyId: string): Observable<any> {
    return this.http.get(
      `${baseURL}/users/dealAnalyst/${companyId}/getUserList`
    );
  }
  notificationMail(body: any) {
    return this.http.post(`${baseURL}/recovery/repayments/unsendMails`, body);
  }
  sendCredstoSpecificUsers(data: any): Observable<any> {
    return this.http.post(
      `${baseURL}/users/dealAnalyst/sendCredentailsToSelectedUsers`,
      data
    );
  }

  fetchDealSummary(applicationId): Observable<any> {
    return this.http.get(
      `${baseURL}/deal/dealMember/${applicationId}/dealSummery`
    );
  }
  addCommentinCommentRepo(payload): Observable<any> {
    return this.http.post(`${baseURL}/openApis/addComment`, payload);
  }
  fetchCommentsFromCollectionCommentsRepo(params): Observable<any> {
    return this.http.get(`${baseURL}/openApis/getComments`, { params: params });
  }
  submitHandOverStatus(id: any, data: any) {
    return this.http.post(
      `${baseURL}/applications/Operations/${id}/submitHandOverStatus`,
      data
    );
  }
  submitHandOverComments(id: any, data: any) {
    return this.http.post(
      `${baseURL}/applications/Operations/${id}/submitHandOverComments`,
      data
    );
  }
  allowUserToCreateApplication(companyId): Observable<any> {
    return this.http.get(
      `${baseURL}/company/dealManager/${companyId}/applicationCreation`
    );
  }

  createApplication(params: any): Observable<any> {
    return this.http.post(`${this.roleWiseUrl}/newApp/newApplication`, params);
  }

  addReferredBy(applicationId: string, referredBy: string): Observable<any> {
    return this.http.post(
      `${baseURL}/applications/dm/${applicationId}/updateRefferedBy`,
      { referredBy }
    );
  }

  getAdminUserWiseApplicatiom(
    page,
    status: any,
    companyName: string = '',
    count
  ) {
    count = +count;
    var params = {};
    (status && (params = { page, company: companyName, count, status })) ||
      (params = { page, company: companyName, count });
    return this.http.get(`${this.roleWiseUrl}/newApp/list`, { params: params });
  }

  leadStage(data: any): Observable<any> {
    const payload = { leadCRMStage: data.stage };
    return this.http.post(
      `${baseURL}/applications/${data.applicationId}/updateLSQRes`,
      payload
    );
  }
  addCompanyUsers$(data: any, companyid: string) {
    return this.http.post(
      `${baseURL}/users/registerUsr/${companyid}/addUser`,
      data
    );
  }
  applicationData(data: any, termsheetId: string) {
    return this.http.post(
      `${baseURL}/termsheet/analyst/${termsheetId}/generateIncredApplication`,
      data
    );
  }
  recoveryAccounts(dealId) {
    return this.http.get(
      `${baseURL}/deal/OpsExecutive/${dealId}/recoveryAccounts`
    );
  }
  revShareSubmit(payload: any) {
    return this.http.post(
      `${baseURL}/deal/OpsExecutive/addRecoveryPGRevShare`,
      payload
    );
  }
  aadharKycDetails(id: any) {
    return this.http.get(`${baseURL}/kyc/list/${id}`);
  }
  requestKyc(payload: any) {
    return this.http.post(`${baseURL}/kyc/kycRequest`, payload);
  }
  resendRequest(_id: any) {
    return this.http.get(`${baseURL}/kyc/kycRequest/${_id}`);
  }
  downloadAadhar(id: any) {
    return this.http.get(`${baseURL}/kyc/downloadAadhar/${id}`);
  }
  getAllCompanies() {
    return this.http.get(`${baseURL}/companies/allCompanies`);
  }

  /* ====GET COMPANY BY PROPOSALS==== */
  fetchCompanyByProposal(): Observable<any> {
    return this.http.get(
      `${baseURL}/proposalDocument/all/companyList`
    );
  }

  getProposalDocCounts() {
    return this.http.get(`${baseURL}/applications/IC/ProposalDocumentCounts`);
  }

  getProposalDocList(payload, status, page, count) {
    return this.http.post(
      `${baseURL}/applications/IC/applicationsByPDUsingCI?status=${status}&page=${page}&count=${count}&financeType=RBF`,
      payload
    );
  }
  submitPrerequisitesCheckLists(applicationId: any, payload: any) {
    return this.http.post(
      `${baseURL}/deal/OpsExecutive/${applicationId}/addDealPrerequisites`,
      payload
    );
  }
  getPrerequisitesCheckLists(applicationId: any) {
    return this.http.get(
      `${baseURL}/deal/OpsExecutive/${applicationId}/getDealPrerequisites`
    );
  }
  uploadPaymentFile(fp: any, file: any) {
    console.log(fp);
    return this.http.post(`${baseURL}/fp/repayment/FPRepayment?fp=${fp}`, file);
  }
  getFundingPartners() {
    return this.http.get(`${baseURL}/deal/investingPartner`);
  }
  submitVerifiedPayments(fp: any, payload: any) {
    return this.http.post(
      `${baseURL}/fp/repayment/generateRepaymentDisFile?fp=${fp}`,
      payload
    );
  }
  getHosttoHostDetail(queryParams: any) {
    return this.http.get(`${baseURL}/fp/repayment/getFPRepayments`, {
      params: { ...queryParams },
    });
  }
  fetchCollectionData(queryParams: any, body: any) {
    return this.http.post(
      `${baseURL}/collection/collection-dashboard/deals`,
      body,
      { params: { ...queryParams } }
    );
  }
  getDealData(dealId: any) {
    return this.http.get(
      `${baseURL}/collection/collection-dashboard/${dealId}/dealCollectionDetails`
    );
  }
  
  getHandoverStageStatusAndSubStatusList() {
    return this.http.get(`${this.roleWiseUrl}/loadHandoverStageStatusSubStatus`);
  }

  getDealComments(dealId: any) {
    return this.http.get(
      `${baseURL}/collection/collection-dashboard/${dealId}/comments`
    );
  }
  submitComment(body: any) {
    return this.http.post(
      `${baseURL}/collection/collection-dashboard/addCollectionComment`,
      body
    );
  }
  fetchDealDataForRecovery(payload: any) {
    let params = payload;
    console.log('💎', payload);
    return this.http.get(
      `${baseURL}/collection/collection-dashboard/recoveryDetails`,
      { params }
    );
  }
  fetchFilterDetailsForRecovery() {
    return this.http.get(
      `${baseURL}/collection/collection-dashboard/DealTotalandCounts`
    );
  }
  getDpdDetails(dealId: any) {
    return this.http.get(`${baseURL}/DPD/Collection/${dealId}/dpdCycles`);
  }
  dpdTransactionDetails(page: any, count: any, body: any) {
    return this.http.post(
      `${baseURL}/DPD/Collection/dpdTransactionDetails?page=${page}&count=${count}`,
      body
    );
  }
  downloadLedger(payload) {
    const headers: HttpHeaders = new HttpHeaders({
      'content-type': 'application/json',
      Accept: 'application/zip',
    });
    return this.http.post(
      `${baseURL}/recovery/repayments/sendLedgerByFp`,
      payload,
      { headers: headers, responseType: 'arraybuffer' }
    );
  }

  addCommentCollection(payload, dealId) {
    return this.http.post(
      `${baseURL}/DPD/Collection/${dealId}/addComment`,
      payload
    );
  }

  getCommentCollection(dealId) {
    return this.http.get(`${baseURL}/DPD/Collection/${dealId}/comments`);
  }

  getDataFromDmi(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/getDataFromDMI`
    );
  }

  fetchLeadData(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/fetchAndAddLeadDetails `
    );
  }

  fetchContactData(id: any, appId: any) {
    return this.http.get(
      ` ${baseURL}/fp/dmiService/${id}/${appId}/fetchAndAddContactDetails`
    );
  }

  submitDmiData(id: any, appId: any, body) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/inputLeadDetails`,
      body
    );
  }

  submitContactData(id: any, appId: any, objectId: any, body) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/inputContactDetails`,
      body
    );
  }
  removeContactData(payload: any, appId: any, id: any) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/deleteContactDetails`,
      payload
    );
  }
  sendLeadDatatoDmi(id: any, appId: any, objectId: any, body) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/sendLeadDetailsToDMI`,
      body
    );
  }
  submitContactDatatoDmi(id: any, appId: any, objectId: any, body) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/${objectId}/sendContactDetailsToDMI`,
      body
    );
  }

  submitFinancialUpload(id: any, appId: any, body) {
    return this.http.post(
      `${baseURL}/FP/financialService/${id}/${appId}/financials`,
      body
    );
  }

  getFinancialUpload(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/FP/financialService/${id}/${appId}/getFinancials`
    );
  }

  getcontactDataFromDmi(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/getContactDataFromDMI`
    );
  }

  uploadBankStatementToDmi(companyId: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiBankStatementService/company/${companyId}/app/${appId}/uploadBankStatementToDMIBucket`
    );
  }

  uploadDocumnetsDMIDeals(
    id: any,
    appId: any,
    objectId: any,
    docType: any,
    body
  ) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/uploadDmiDocuments?documentType=${docType}&objectId=${objectId}`,
      body
    );
  }
  uploadIdDocumentDMIDeals(id: any, appId: any, body) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/uploadIdDocuments?documentType=ID_DOC`,
      body
    );
  }

  resetIdDoc(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/FP/dmiService/${id}/${appId}/resetIdDoc`
    );
  }

  gstContactData(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/FP/gstService/${id}/${appId}/gst?serviceType=GSTContact`
    );
  }

  updateContact(id: any, appId: any, objectId: any, contactId: any, body) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/${objectId}/${contactId}/updateContactDetail`,
      body
    );
  }

  bankContactDetailandAggr(id: any, appid: any, query: any) {
    return this.http.get(
      `${baseURL}/fp/all/${id}/${appid}/DMIBankContactDetails?type=${query}`
    );
  }

  // bankAggregation(id: any, appid: any) {
  //   return this.http.get(
  //     `${baseURL}/fp/repayment/${id}/${appid}/DMIBankContactDetails?type=aggr`
  //   );
  // }

  getApplicationDetails(appId: any) {
    return this.http.get(`${this.openUrl}/${appId}/applicationById`);
  }

  fetchOppurtunityData(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/fetchAndAddOpportunityData`
    );
  }
  submitOppurtunityId(id: any, appId: any, body: any) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/inputOpportunityId`,
      body
    );
  }
  submitOppurtunityData(id: any, appId: any, body: any) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/inputOpportunityData`,
      body
    );
  }

  inputDrawdownData(id: any, appId: any, body: any) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/inputDrawdown`,
      body
    );
  }

  updateOppurtunity(id: any, appId: any, oppId: any, body: any, type: any) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/${oppId}/updateOpportunity?type=${type}`,
      body
    );
  }

  getOppurtunity(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/getOppDataFromDMICollection`
    );
  }

  downloadDmiDocument(Id: any, appId: any, body) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${Id}/${appId}/downloadOtherStatement`,
      body
    );
  }
  downloadDMIBankStatement(Id: any, appId: any, body) {
    const headers: HttpHeaders = new HttpHeaders({
      'content-type': 'application/json',
      Accept: 'application/zip',
    });
    return this.http.post(
      `${baseURL}/fp/dmiService/${Id}/${appId}/downloadOtherStatement`,
      body,
      { headers: headers, responseType: 'arraybuffer' }
    );
  }

  downloadDMIBankContactDetails(Id: any, appId: any, body: any) {
    const headers: HttpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });
    return this.http.post(
      `${baseURL}/fp/dmiService/${Id}/${appId}/downloadOtherStatement`,
      body,
      { headers: headers, responseType: 'blob' }
    );
  }

  getDrawdown(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/getDrawdownDataFromDMICollection`
    );
  }


  checkRPSData(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/checkRPSDataFromRPSCollection`
    );
  }

  validateRPSData(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/varifyRPSData`
    );
  }

  submitRPSToDMI(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/rpsCreation`
    );
  }

  getAPRData(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/getAprDetail`
    );
  }

  inputAPRData(id: any, appId: any, body: any) {
    console.log("check body:", body)
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/inputAprDetail`,
      body
    );
  }


  fetchDrawdownData(id: any, appId: any) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${id}/${appId}/fetchAndAddDrawdown `
    );
  }

  sendDrawdownToDmi(id: any, appId: any, objectId: any, body) {
    return this.http.post(
      `${baseURL}/fp/dmiService/${id}/${appId}/${objectId}/sendDrawdownToDmi`,
      body
    );
  }

  nonDisbursedDeals(page: any, count: any, company: any) {
    return this.http.get(
      `${baseURL}/deal/OpsRecovery/nonDisbursedDeals?page=${page}&count=${count}&company=${company}`
    );
  }

  getGstList(companyId: any, applicationId) {
    return this.http.get(
      `${baseURL}/fp/dmiService/${companyId}/${applicationId}/getGSTINDetail`
    );
  }

  parseGSTFile(payload: any) {
    return this.http.post(`${baseURL}/FP/gstService/gstParse`, payload);
  }

  collateParseData(companyId: any, applicationId: any) {
    return this.http.get(
      `${baseURL}/FP/gstService/${companyId}/${applicationId}/gstCollate`
    );
  }
  getInvestingPartner() {
    return this.http.get(`${baseURL}/applications/IC/InvestingPartners`);
  }

  addDpdWaiveOff(payload: any) {
    return this.http.post(`${baseURL}/DPD/Collection/WaiveOffAmount`, payload);
  }

  updateDealAnalyst(companyId: any, applicationId: any, analystId: any) {
    return this.http.get(
      `${baseURL}/applications/openAPI/${companyId}/${applicationId}/${analystId}/changeApplicationAnalyst`
    );
  }

  fetchChannelPartnerAppl(page?: string, count?: string, payload?: any) {
    return this.http.post(
      `${baseURL}/channel/channelPartner/listCpApplications`,
      payload,
      { params: { page, count } }
    );
  }

  fetchChannelPartnerComment(applicationId: any) {
    return this.http.get(
      `${baseURL}/cpcomments/${applicationId}/listCPComments`
    );
  }

  addChannelPartnerComment(applicationId: any, payload: any) {
    return this.http.post(
      `${baseURL}/cpcomments/${applicationId}/addCPComment`,
      payload
    );
  }

  updateCpApplicationStatus(companyId, applicationId, payload) {
    return this.http.post(
      `${baseURL}/channel/channelPartner/${companyId}/${applicationId}/updateApplicationStatus`,
      payload
    );
  }
  getHandOverComments(id: any) {
    return this.post(
      `${baseURL}/applications/Operations/${id}/getHandOverComments`
    );
  }
  fetchAllChannelPartner() {
    return this.http.get(
      `${baseURL}/channel/channelPartner/listAllChannelPartner`
    );
  }

  fetchChannelPartner(id? : any )
  {
    let payload = { id}
    console.log("Id ::" , id )
    return this.http.post(`${baseURL}/channel/channelPartner/listChannelPartner` , payload);
  }

  editCPUser(payload: any): Observable<any> {
    return this.http.put(
      `${baseURL}/channel/channelPartner/editCPUser`,
      payload
    );
  }

  fetchChannelPartnerUserData(page?: string, count?: string, payload?: object) {
    return this.http.post(
      `${baseURL}/channel/channelPartner/listUserWiseData?page=${page}&count=${count}`,
      payload
    );
  }

  updateCpPriority(payload: any): Observable<any>{
    return this.http.put(
      `${baseURL}/channel/channelPartner/editpriority`,
      payload
    );
  }

  fecthAllChannelPartnerMarketData(
    page?: number,
    count?: number,
    payload?: object
  ) {
    return this.http.post(
      `${baseURL}/channel/channelPartner/listAllChannelPartnerMarketData?page=${page}&count=${count}`,
      payload
    );
  }

  fetchDealType(appId: any) {
    return this.http.get(
      `${baseURL}/applications/CreditToOps/${appId}/getDealType`
    );
  }

  addLead(payload: any): Observable<any> {
    return this.http.post(`${baseURL}/leads/leadAPI/addLead`, payload);
  }

  uploadBulkleads(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(`${baseURL}/leads/leadAPI/uploadBulkleads`, formdata);
  }

  bulkLead(payload: any): Observable<any> {
    return this.http.post(`${baseURL}/leads/leadAPI/bulkLead`, payload);
  }

  addLeadComment(leadId: string, params: any) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/${leadId}/addComment`,
      params
    );
  }

  getLeadComments(leadId: string): Observable<any> {
    return this.http.get(`${baseURL}/leads/leadAPI/${leadId}/commentList`);
  }

  addTask(leadId: string, params: any) {
    return this.http.post(`${baseURL}/leads/leadAPI/${leadId}/addTask`, params);
  }

  updateTaskStatusWithComment(leadId: string, taskId: string, data: any) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/${leadId}/${taskId}/updateTaskStatusWithComment`,
      data
    );
  }

  getTaskList(leadId: string): Observable<any> {
    return this.http.get(`${baseURL}/leads/leadAPI/${leadId}/taskList`);
  }

  getTaskCommentList(leadId: string, taskId: string): Observable<any> {
    return this.http.get(
      `${baseURL}/leads/leadAPI/${leadId}/${taskId}/taskCommentList`
    );
  }

  updateLeadStatus(leadId: string, data: any) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/${leadId}/updateLeadStatus`,
      data
    );
  }
  addNewApplication(payload: any): Observable<any> {
    return this.http.post(
      `${baseURL}/leads/leadAPI/convertToNewApplication`,
      payload
    );
  }

  getDealAnalystList() {
    return this.http.get(`${baseURL}/leads/leadAPI/getDealAnalystList`);
  }

  getDealManagerList() {
    return this.http.get(`${baseURL}/leads/leadAPI/getDealManagerList`);
  }

  getDealAnalystListForApplicationDealReassign() {
    return this.http.get(
      `${baseURL}/leads/leadAPI/getDealAnalystListForApplicationDealReassign`
    );
  }

  getDealManagerListForApplicationDealReassign() {
    return this.http.get(
      `${baseURL}/leads/leadAPI/getDealManagerListForApplicationDealReassign`
    );
  }

  getDealAnalystByUsingDealManagerId(dealManagerID) {
    return this.http.get(
      `${baseURL}/leads/leadAPI/getDealAnalystByUsingDealManagerId?dealManagerID=${dealManagerID}`
    );
  }

  updateStatusWithComment(leadId: string, data: any) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/${leadId}/updateStatusWithComment`,
      data
    );
  }
  leadList(page?: number, count?: number, payload?: object) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/leadList?page=${page}&count=${count}`,
      payload
    );
  }

  getDocumentChecklistStatus(companyId: string): Observable<any> {
    return this.http.get(
      `${baseURL}/leads/leadAPI/documentChecklistStatus/${companyId}`
    );
  }

  getAllCompanyList() {
    return this.http.get(`${baseURL}/leads/leadAPI/allCompanies`);
  }
  
  loadStageStatusSubStatusList() {
    return this.http.get(`${baseURL}/leads/leadAPI/loadStageStatusSubStatus`);
  }
  getDealStageStatusAndSubStatusList() {
    return this.http.get(`${this.roleWiseUrl}/loadDealStageStatusSubStatus`);
  }

  getCreditStageStatusAndSubStatusList() {
    return this.http.get(`${this.roleWiseUrl}/loadCreditStageStatusSubStatus`);
  }

  getProposalDocumentStageStatusAndSubStatusList() {
    return this.http.get(`${this.roleWiseUrl}/loadProposalDocumentStageStatusSubStatus`);
  }

  createOpportunity(companyId: string, payload?: any) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/${companyId}/createOpportunity`,
      payload
    );
  }
  getDealAnalystByUsingAssociateDirectorId(associateDirectorId) {
    return this.http.get(
      `${baseURL}/leads/leadAPI/getDealAnalystByUsingAssociateDirectorId?associateDirectorId=${associateDirectorId}`
    );
  }
  getAssociateDirector() {
    return this.http.get(`${baseURL}/leads/leadAPI/getAssociateDirectorList`);
  }

  leadReassign(leadId: string, payload?: any) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/${leadId}/leadReassign`,
      payload
    );
  }
  getAllCompanyOfLeadList() {
    return this.http.get(`${baseURL}/leads/leadAPI/allCompaniesNameOfLeadList`);
  }
  getAllUserList() {
    return this.http.get(`${baseURL}/leads/leadAPI/getUsersList`);
  }
  applicationReassignForDeal(
    companyId: string,
    applicationId: string,
    payload?: any
  ) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/${companyId}/${applicationId}/leadReassignForDeal`,
      payload
    );
  }
  getCreditManagerList() {
    return this.http.get(`${baseURL}/leads/leadAPI/getCreditManagerList`);
  }
  getCreditAnalystList() {
    return this.http.get(`${baseURL}/leads/leadAPI/getCreditAnalystList`);
  }
  getCreditAnalystByUsingCreditManagerId(creditManagerID) {
    return this.http.get(
      `${baseURL}/leads/leadAPI/getCreditAnalystByUsingCreditManagerId?creditManagerID=${creditManagerID}`
    );
  }
  // getCreditAnalystListBasedOnCreditManager() {
  //   return this.http.get(
  //     `${baseURL}/leads/leadAPI/getCreditAnalystListBasedOnCreditManager`
  //   );
  // }
  applicationReassignForCredit(
    companyId: string,
    applicationId: string,
    payload?: any
  ) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/${companyId}/${applicationId}/applicationReassignForCredit`,
      payload
    );
  }
  getLeadDetailsById(leadId: string) {
    return this.http.get(
      `${baseURL}/leads/leadAPI/${leadId}/getLeadDetailsById`
    );
  }
  editLead(leadId: string, payload: any): Observable<any> {
    return this.http.put(
      `${baseURL}/leads/leadAPI/editLead/${leadId}`,
      payload
    );
  }
  getActivityLogs(leadId: string) {
    return this.http.get(`${baseURL}/leads/leadAPI/${leadId}/getActivityLogs`);
  }
  getChannelPartnerList() {
    return this.http.get(`${baseURL}/leads/leadAPI/getChannelPartnerList`);
  }
  getApplicationList(page?: number, count?: number, payload?: object) {
    return this.http.post(
      `${this.roleWiseUrl}/getApplicationList?page=${page}&count=${count}`,
      payload
    );
  }
  updateApplicationStageAndStatusForDealDashboard(
    applicationId: string,
    companyId: string,
    payload?: any
  ) {
    return this.http.post(
      `${this.roleWiseUrl}/${applicationId}/${companyId}/updateApplicationStageAndStatus`,
      payload
    );
  }
  applicationReassignForDealManager(
    companyId: string,
    applicationId: string,
    payload?: any
  ) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/${companyId}/${applicationId}/leadReassignForDealManager`,
      payload
    );
  }
  applicationReassignForCreditManager(
    companyId: string,
    applicationId: string,
    payload?: any
  ) {
    return this.http.post(
      `${baseURL}/leads/leadAPI/${companyId}/${applicationId}/applicationReassignForCreditManager`,
      payload
    );
  }
  updateApplicationStageAndStatusForCreditDashboard(
    applicationId: string,
    companyId: string,
    payload?: any
  ) {
    return this.http.post(
      `${this.roleWiseUrl}/${applicationId}/${companyId}/updateApplicationStageAndStatus`,
      payload
    );
  }

  updateApplicationStageAndStatusForOpsDashboard(
    applicationId: string,
    companyId: string,
    payload?: any
  ) {
    return this.http.post(
      `${this.roleWiseUrl}/${applicationId}/${companyId}/updateApplicationStageAndStatus`,
      payload
    );
  }

  getAllAdminUsersList() {
    return this.http.get(`${baseURL}/leads/leadAPI/getAllAdminUsersList`);
  }

  getStageAndStatusList() {
    return this.http.get(`${baseURL}/leads/leadAPI/getStageAndStatusList`);
  }

  getDealStageAndStatusList() {
    return this.http.get(`${this.roleWiseUrl}/getDealStageAndStatusList`);
  }

  getCreditStageAndStatusList() {
    return this.http.get(`${this.roleWiseUrl}/getCreditStageAndStatusList`);
  }

  getApplicationActivityLogs(appId: string) {
    return this.http.get(`${this.roleWiseUrl}/${appId}/getApplicationActivityLogs`);
  }

  getCreditActivityLogs(appId: string) {
    return this.http.get(`${this.roleWiseUrl}/${appId}/getCreditActivityLogs`);
  }

}
